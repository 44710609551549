<template>
    <div>
        <dealer-chatbot-configuration
            ref="chatbotConfiguration"
            :setup-on-create="!chatbotSetupCompleted && chatbotRequired"
            hide-save
            @setup-complete="chatbotSetupCompleted = true">
            <template #header>
                <h2>Setup Chatbot</h2>

                <p
                    v-if="chatbotRequired"
                    class="mb-4">
                    Your chatbot has been setup and now it's time to configure it!
                    Please review the messages on the <strong>left</strong> below and
                    edit them to suit this dealer's needs accordingly.
                </p>

                <p
                    v-else
                    class="mb-4">
                    If you would like to use our automated chatbot to answer marketplace
                    inquiries you can enable it and configure its behavior below.
                </p>
            </template>
        </dealer-chatbot-configuration>

        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :loading="configUpdateLoading"
                @click="onContinue">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import DealerChatbotConfiguration from '@/components/globals/DealerChatbotConfiguration';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import { mapState, mapGetters } from 'vuex';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';

export default {
    name: 'OnboardingChatbot',
    components: {
        DealerChatbotConfiguration,
        BackButton,
        StyledButton
    },
    data() {
        return {
            configUpdateLoading: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'requiredFeatures',
        ]),
        chatbotRequired() {
            return this.requiredFeatures.includes('chatbot');
        },
        chatbotSetupCompleted: {
            get() {
                return this.onboardingData.chatbotSetupCompleted || false;
            },
            set(isComplete) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    chatbotSetupCompleted: isComplete
                });
            }
        },
    },
    methods: {
        async onContinue() {
            this.configUpdateLoading = true;
            await this.$refs.chatbotConfiguration.save();
            this.configUpdateLoading = false;

            this.$store.dispatch('goToNextStep');
        },
    }
};
</script>
